import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, TextField, Box, Typography, Link } from '@mui/material';

const PhoneNumberField = ({ onChange, field, error }) => {
  const privacyPolicyLink = (
    <Link href="https://www.getbento.com/privacy/" target="_blank">
      Privacy Policy
    </Link>
  );
  const termsOfServiceLink = (
    <Link href="https://www.getbento.com/terms/" target="_blank">
      Terms of Service
    </Link>
  );

  // TODO: Update to include restaurant/merchant name
  const PHONE_NUMBER_CONSENT_TEXT = (
    <Typography
      align="inherit"
      paragraph
      sx={theme => ({
        m: '8px 0',
        ...theme.brandStyles?.body,
        fontSize: '0.75rem'
      })}
    >
      By submitting my phone number in this form, I consent to receive automated
      offers and reward messages at the number provided from the merchant and/or
      its partners (including BentoBox) and confirm that I agree with your{' '}
      {privacyPolicyLink} and {termsOfServiceLink}. Consent is not a condition
      to purchase. Msg&Data rates may apply. Msg frequency varies. Text
      STOP/HELP at any time
    </Typography>
  );

  return (
    <Box key={field.label} marginBottom="1rem">
      <FormControl fullWidth>
        <TextField
          sx={theme => ({
            borderRadius: '4px',
            backgroundColor: theme.brandStyles?.form?.backgroundColor,
            fieldset: {
              border: theme.brandStyles?.form?.border
            },
            input: {
              color: theme.brandStyles?.form?.color,
              textTransform: theme.brandStyles?.form?.textTransform
            }
          })}
          placeholder={field.label}
          name={field.label}
          aria-describedby={field.label}
          inputProps={{ style: { height: '.5rem' } }}
          onChange={onChange}
          helperText={error}
          error={!!error}
        />
      </FormControl>
      {PHONE_NUMBER_CONSENT_TEXT}
    </Box>
  );
};

PhoneNumberField.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  field: PropTypes.shape().isRequired
};

export default PhoneNumberField;

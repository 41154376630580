import { BrowserClient, Hub, makeMain } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_DSN, environmentNames } from 'config/constants';
import getEnvironment from 'helpers/getEnvironment';

let _hub;
let _tempHub;

/**
 * If you want to add a new matcher,
 * please make sure all test pass on "sentryUtils.test.js" file.
 *
 * Also, don't forget to add an example of the event on "excludeEventRealEvents.js" file,
 * The test will automatically pick it up and run the test applying the matchers.
 */
export const excludeEventMatcherList = [
  {
    // https://bentobox.sentry.io/issues/3337410252/events/latest/?project=6343811&referrer=latest-event
    type: 'Error',
    value: 'Request failed with status code 404'
  }
];

/**
 * This function calculates if an event should be to send to sentry
 * or if we want to exclude it.
 *
 * @param {Object} event Sentry event
 * @param {Object} eventMatcher Matcher settings
 * @param {string} [eventMatcher.type] The event type. For example: TypeError
 * @param {string} [eventMatcher.value] The event message. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.message] The event message in case is not an exception. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.file] The source file path or string. For example: http://bentobox.com/file.js or just file.js
 *
 * @returns boolean
 */
export function excludeEvent(event, eventMatcher) {
  try {
    if (event.exception) {
      const sentryExcept =
        event.exception.values[event.exception.values.length - 1];

      if (eventMatcher.type && eventMatcher.value) {
        const typeMatches = sentryExcept.type.includes(eventMatcher.type);
        const valueMatches = sentryExcept.value.includes(eventMatcher.value);

        if (typeMatches && valueMatches) {
          return true; // Match found for type and value
        }
      }

      if (eventMatcher.file) {
        const frames = sentryExcept.stacktrace?.frames || [];
        const frameMatches = frames.some(frame => {
          const filenameMatches = (frame.filename ?? '').includes(
            eventMatcher.file
          );
          const absPathMatches = (frame.abs_path ?? '').includes(
            eventMatcher.file
          );
          return filenameMatches || absPathMatches;
        });

        if (frameMatches) {
          return true; // Match found for file
        }
      }
    }
    if (eventMatcher.message && event.message) {
      return event.message.includes(eventMatcher.message); // Match found for message
    }
  } catch (error) {
    return false; // Error occurred, return false
  }

  return false; // No match found, return false
}

export const _beforeSend = event => {
  const ignoreEvent = excludeEventMatcherList.some(matcher =>
    excludeEvent(event, matcher)
  );
  if (ignoreEvent) {
    return null;
  }
  event.request = {
    url: window.location.href,
    headers: {
      'User-Agent': window.navigator.userAgent
    }
  };
  return event;
};

export const initializeSentry = () => {
  const { REACT_APP_VERSION } = process.env;

  const ENVIRONMENT_NAME = getEnvironment();

  const client = new BrowserClient({
    // add DEVELOPMENT into the array below if you want sentry logging for local development
    dsn: [environmentNames.PRODUCTION, environmentNames.STAGING].includes(
      ENVIRONMENT_NAME
    )
      ? SENTRY_DSN
      : '',
    debug: false,
    defaultIntegrations: false,
    release: REACT_APP_VERSION,
    environment: ENVIRONMENT_NAME,
    sampleRate: 1.0,
    maxBreadcrumbs: 100,
    normalizeDepth: 3,
    integrations: [new BrowserTracing()],
    beforeSend: _beforeSend,
    ignoreErrors: ['prepend is not a function']
  });

  _hub = new Hub(client);
};

export const logExceptionToSentry = (e, message) => {
  if (!_hub) {
    initializeSentry();
  }
  _hub.captureException(e, {
    captureContext: { extra: { message, error: JSON.stringify(e) } }
  });
};

export const logToSentry = (message, level) => {
  if (!_hub) {
    initializeSentry();
  }
  _hub.captureMessage(message, level);
};

/**
 * Set local Sentry hub to be the main hub before
 * an error is logged via the Sentry ErrorBoundary
 */
export const setMainHub = () => {
  _tempHub = makeMain(_hub);
};

/**
 * Resets main Sentry hub after
 * an error is logged via the Sentry ErrorBoundary
 */
export const resetMainHub = () => {
  makeMain(_tempHub);
  _tempHub = null;
};

/**
 * Primarily used for testing purposes
 */
export const clearMainHub = () => {
  _hub = null;
};

/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material';

import AlertModal from 'components/AlertModal';
import ContentSection from 'components/ContentSection';
import {
  FORM_STATES,
  PROMOTION,
  ANNOUNCEMENT,
  CUSTOM,
  FUJI,
  EXTERNAL_LINK,
  PAGE_LINK,
  MERCH_LINK,
  CLOSE_POPUP,
  NONE,
  SUBMIT_FORM
} from 'constants/dinerSignupConstants';
import { getCSRFToken, getStyles } from 'api';
import FujiOverlayAlertContent from 'components/fuji/OverlayAlertContent';
import SenseiOsakaOverlayAlertContent from 'components/senseiOsaka/OverlayAlertContent';

import {
  alertPreviouslySubmitted,
  shouldDisplayAlertBasedOnCadence
} from 'utils/dinerSignup';
import { entries } from 'utils/entries';
import { getSubmitFormPath, getSubmitPhoneNumberPath } from 'api/helpers';
import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import { actionTypes, eventLabels } from 'constants/tracking';

const DinerSignupAlertContainer = ({
  id,
  alert_content_context,
  alert_form_context,
  display_cadence,
  template,
  legacy_template,
  preview,
  title
}) => {
  // Preview is set to true when alert is opened in preview in sushi
  // We use this to disable form submission in preview
  const isPreview = preview !== undefined;

  const [formState, setFormState] = useState(
    preview?.formState ?? FORM_STATES.INITIAL
  );

  // PROMOTION template: phone number field should be enabled to display second alert step
  // ANNOUNCEMENT template: phone number field should be enabled and optional_alert_form_fields_enabled
  // ... set to true to display second alert step
  const isPhoneNumberFieldEnabled = useMemo(
    () =>
      !!alert_form_context?.alert_form_fields?.find(
        field => field.input_type === 'phone_number' && field.enabled === true
      ),
    [alert_form_context]
  );

  // Used to display phone number field as a secondary step on alerts when is True
  const secondaryContentEnabled =
    (template === PROMOTION && isPhoneNumberFieldEnabled) ||
    (template === ANNOUNCEMENT &&
      isPhoneNumberFieldEnabled &&
      alert_form_context?.optional_alert_form_fields_enabled);

  // Display Next on initial step if secondary step is enabled
  const customButtonLabel =
    secondaryContentEnabled && formState === FORM_STATES.INITIAL
      ? 'Next'
      : null;

  // This value will be used to switch between different steps from preview in sushi
  useEffect(() => {
    if (isPreview) {
      setFormState(preview.formState);
    }
  }, [preview?.formState]);

  useEffect(() => {
    // When user deselects phone number field, switch the preview to the initial step
    const shouldResetToInitialState =
      formState === FORM_STATES.SECONDARY && !secondaryContentEnabled;

    if (isPreview && shouldResetToInitialState) {
      setFormState(FORM_STATES.INITIAL);
    }
  }, [secondaryContentEnabled]);

  if (!alert_content_context || entries(alert_content_context).length < 1) {
    return null;
  }

  const contentContextMap = {
    [FORM_STATES.INITIAL]: {
      header: alert_content_context.header,
      body: alert_content_context.body,
      alertButton: alert_content_context.alert_button,
      image: alert_content_context.image,
      images: alert_content_context.images,
      html_content: alert_content_context.html_content
    },
    [FORM_STATES.SECONDARY]: {
      header: alert_content_context.header,
      body: alert_content_context.body,
      alertButton: alert_content_context.alert_button,
      image: alert_content_context.image,
      images: alert_content_context.images,
      html_content: alert_content_context.html_content
    },
    [FORM_STATES.SUCCESS]: {
      header: alert_form_context?.success_header,
      body: alert_form_context?.success_body,
      alertButton: alert_form_context?.alert_buttons?.find(
        button => button.button_type === 'success'
      ),
      image: alert_content_context.image
    }
  };

  const context = contentContextMap[formState];

  const [isOpen, setIsOpen] = useState(true);

  const [email, setEmail] = useState();
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState();

  const [shouldDisplayAlert, setShouldDisplayAlert] = useState(false);
  const [alertsTheme, setAlertsTheme] = useState();

  // Template endpoints for each step
  const endpointsMap = {
    [FORM_STATES.INITIAL]: getSubmitFormPath,
    [FORM_STATES.SECONDARY]: getSubmitPhoneNumberPath
  };

  // Template data to pass for each call on each step
  const customerDataMap = {
    [FORM_STATES.INITIAL]: {
      email,
      // Both day and month have to be passed to save birthday
      ...(day && month && { birthday: `${month}/${day}` })
    },
    [FORM_STATES.SECONDARY]: {
      // Email will be used to save phone number to previously created customer
      email,
      phone_number: phoneNumber
    }
  };

  // Template submit form
  const handleSubmitForm = async () => {
    setIsLoading(true);
    const csrfToken = await getCSRFToken();

    const endpoint = endpointsMap[formState](id);
    const customerData = customerDataMap[formState];

    try {
      const response = await axios.post(endpoint, customerData, {
        headers: { 'X-CSRFToken': csrfToken }
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      return error.response;
    }
  };

  // PROMOTION Template primary button actions in preview
  // Used to skip form submission in preview in sushi
  // Disable all for now after adding tabs to switch between form pages
  const handleButtonClickInPromotionPreview = () => {};

  // ANNOUNCEMENT Template primary button actions in preview
  // Disable all for now after adding tabs to switch between form pages
  const handleButtonClickInAnnouncementPreview = () => {};

  const templatePreviewActionMap = {
    [PROMOTION]: handleButtonClickInPromotionPreview,
    [ANNOUNCEMENT]: handleButtonClickInAnnouncementPreview
  };

  const handleButtonClickInPreview = templatePreviewActionMap[template];

  // ANNOUNCEMENT Template open in new URL action
  const handleOpenURLInNewTab = button => {
    let buttonUrl = button.url;
    if (!button.url.startsWith('http')) {
      buttonUrl = `https://${button.url}`;
    }
    // See https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
    const newWindow = window.open(buttonUrl, '_blank', 'noopener,noreferrer');
    setIsOpen(false);
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const alertFormStepsActionsMap = {
    [FORM_STATES.INITIAL]: async () => {
      const response = await handleSubmitForm();

      if (response.status === 400) {
        setErrors(response.data);
        return;
      }

      // If phone number input field is not enabled, skip to success page
      setFormState(
        secondaryContentEnabled ? FORM_STATES.SECONDARY : FORM_STATES.SUCCESS
      );
    },
    [FORM_STATES.SECONDARY]: async () => {
      const response = await handleSubmitForm();

      if (response.status === 400) {
        setErrors(response.data);
        return;
      }

      setFormState(FORM_STATES.SUCCESS);
    }
  };

  // Template submit primary button actions
  const handleSubmitNextAction = async () =>
    alertFormStepsActionsMap[formState]();

  // PROMOTION Template actions map
  const promotionActionsMap = {
    [SUBMIT_FORM]: handleSubmitNextAction,
    [CLOSE_POPUP]: () => {
      setIsOpen(false);
    }
  };

  // ANNOUNCEMENT Template actions map
  const announcementActionsMap = {
    [SUBMIT_FORM]: handleSubmitNextAction,
    [EXTERNAL_LINK]: handleOpenURLInNewTab,
    [MERCH_LINK]: handleOpenURLInNewTab,
    [PAGE_LINK]: handleOpenURLInNewTab,
    [CLOSE_POPUP]: () => {
      setIsOpen(false);
    },
    [NONE]: () => {}
  };

  const templateActionsMap = {
    [PROMOTION]: promotionActionsMap,
    [ANNOUNCEMENT]: announcementActionsMap
  };

  // PROMOTION and ANNOUNCEMENT template onClick action
  const handleButtonClick = async button => {
    trackEventWithBentoAnalytics(
      actionTypes.CLICK,
      `${button.label}${formState !== FORM_STATES.SUCCESS ? `: ${email}` : ''}`
    );

    // Get actions map by template, then get by button action and call it
    // await is needed for async submit form action
    await templateActionsMap[template][button.action](button);
  };

  // Modal close icon
  const handleCloseModal = (label = null) => {
    if (isPreview) {
      return;
    }

    setIsOpen(false);
    trackEventWithBentoAnalytics(
      actionTypes.CLICK,
      `${eventLabels.ALERT_CLOSE_BUTTON}${label ? `: ${label}` : ''}`
    );
  };

  const handleEmailChange = e => setEmail(e.target.value);
  const handlePhoneNumberChange = e => setPhoneNumber(e.target.value);
  const handleBirthdayChange = e => {
    setMonth(e.month);
    setDay(e.day);
  };

  useEffect(() => {
    const shouldAlertBeDisplayed =
      (!!alertsTheme || template === CUSTOM) &&
      !alertPreviouslySubmitted(id) &&
      shouldDisplayAlertBasedOnCadence(id, display_cadence);

    setShouldDisplayAlert(shouldAlertBeDisplayed);
  }, [id, display_cadence, alertsTheme]);

  useEffect(() => {
    const fetchStylesAndCreateTheme = async () => {
      const alertStyles = await getStyles(preview?.hostname);
      if (alertStyles) {
        setAlertsTheme(
          createTheme({
            typography: {
              fontFamily: alertStyles.body?.fontFamily,
              h3: {
                fontFamily: alertStyles.header?.fontFamily
              }
            },
            brandStyles: alertStyles
          })
        );
      }
    };
    // For CUSTOM template alerts, the styling comes from the website theme, not from the endpoint.
    if (template !== CUSTOM) {
      fetchStylesAndCreateTheme();
    }
  }, []);

  if (!shouldDisplayAlert) {
    return null;
  }

  if (template === CUSTOM) {
    // For CUSTOM template alerts, we display the existing OverlayAlertContent (we have one for Fuji and SenseiOsaka).
    // This will allow legacy alerts to continue displaying in the same way,
    // as well as new CUSTOM alerts to continue supporting custom css.
    const LegacyComponent =
      legacy_template.toLowerCase() === FUJI
        ? FujiOverlayAlertContent
        : SenseiOsakaOverlayAlertContent;

    return (
      <LegacyComponent
        content={context.html_content}
        images={context.images}
        title={title}
      />
    );
  }

  return (
    <ThemeProvider theme={alertsTheme}>
      <AlertModal
        open={isOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <ContentSection
          onClose={handleCloseModal}
          alert_form_context={alert_form_context}
          context={context}
          onClick={isPreview ? handleButtonClickInPreview : handleButtonClick}
          handleEmailChange={handleEmailChange}
          handleBirthdayChange={handleBirthdayChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          errors={errors}
          template={template}
          formState={formState}
          isLoading={isLoading}
          customButtonLabel={customButtonLabel}
        />
      </AlertModal>
    </ThemeProvider>
  );
};

DinerSignupAlertContainer.propTypes = {
  alert_content_context: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image_url: PropTypes.string
    }),
    alert_button: PropTypes.shape({}),
    images: PropTypes.arrayOf(),
    html_content: PropTypes.string
  }),
  alert_form_context: PropTypes.shape({
    success_body: PropTypes.string,
    success_header: PropTypes.string,
    alert_buttons: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        url: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        id: PropTypes.number.isRequired
      })
    ),
    display_success: PropTypes.bool,
    optional_alert_form_fields_enabled: PropTypes.bool,
    alert_form_fields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        enabled: PropTypes.bool,
        input_type: PropTypes.string,
        label: PropTypes.string
      })
    )
  }),
  display_cadence: PropTypes.string,
  template: PropTypes.string,
  legacy_template: PropTypes.string,
  title: PropTypes.string,
  // Ideally should remain in sync with sushi's src/alerts/contexts/previewStateContext.js
  preview: PropTypes.shape({
    formState: PropTypes.oneOf(Object.values(FORM_STATES)),
    hostname: PropTypes.string
  }),
  id: PropTypes.number.isRequired
};

DinerSignupAlertContainer.defaultProps = {
  alert_content_context: {},
  alert_form_context: {},
  display_cadence: '',
  template: '',
  legacy_template: '',
  title: '',
  preview: undefined
};

export default DinerSignupAlertContainer;
